/* Variables */
:root {
  --main-color: #6bbecd;
  --secondary-color: #ea803f;
  --thirdy-color: #61439a;
  --background-color: #f8f9fa;
  --font-family: "Montserrat", sans-serif;
  --second-font-family: "Montserrat Alternates", sans-serif;
}

$main-color: var(--main-color);
$secondary-color: var(--secondary-color);
$thirdy-color: var(--thirdy-color);
$background-color: var(--background-color);

//Fonts
$font-family-base: var(--font-family); //"Montserrat", sans-serif;
$second-font-family: var(
  --second-font-family
); //"Montserrat Alternates", sans-serif;

body {
  font-family: var(--font-family);
}
@font-face {
  font-family: 'Playlist Script';
  src: url('./assets/fonts/playlist-script.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//Quill styles
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="8px"]::before {
//   content: "8px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="10px"]::before {
//   content: "10px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="12px"]::before {
//   content: "12px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="14px"]::before {
//   content: "14px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="16px"]::before {
//   content: "16px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="18px"]::before {
//   content: "18px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="20px"]::before {
//   content: "20px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="22px"]::before {
//   content: "22px" !important;
// }

// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before,
// .ql-snow
//   .ql-picker.ql-size
//   .ql-picker-label.ql-active[data-value="24px"]::before {
//   content: "24px" !important;
// }
@import url('https://fonts.googleapis.com/css2?family=Arial:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Sans+MS&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+New:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;700&display=swap');


.ql-font-arial { font-family: 'Arial', sans-serif !important; }
.ql-font-comic-sans { font-family: 'Comic Sans MS', cursive, sans-serif !important; }
.ql-font-courier-new { font-family: 'Courier New', monospace !important; }
.ql-font-dancing-script { font-family: 'Dancing Script', cursive !important; }
.ql-font-fira-code { font-family: 'Fira Code', monospace !important; }
.ql-font-ibm-plex-mono { font-family: 'IBM Plex Mono', monospace !important; }
.ql-font-inter { font-family: 'Inter', sans-serif !important; }
.ql-font-jetbrains-mono { font-family: 'JetBrains Mono', monospace !important; }
.ql-font-lato { font-family: 'Lato', sans-serif !important; }
.ql-font-merriweather { font-family: 'Merriweather', serif !important; }  /* 🔥 Faltaba */
.ql-font-montserrat { font-family: 'Montserrat', sans-serif !important; }
.ql-font-nunito { font-family: 'Nunito', sans-serif !important; }
.ql-font-open-sans { font-family: 'Open Sans', sans-serif !important; }
.ql-font-pacifico { font-family: 'Pacifico', cursive !important; }  /* 🔥 Faltaba */
.ql-font-playfair-display { font-family: 'Playfair Display', serif !important; }
.ql-font-poppins { font-family: 'Poppins', sans-serif !important; }
.ql-font-raleway { font-family: 'Raleway', sans-serif !important; }  /* 🔥 Faltaba */
.ql-font-roboto { font-family: 'Roboto', sans-serif !important; }
.ql-font-source-code-pro { font-family: 'Source Code Pro', monospace !important; }
.ql-font-times-new-roman { font-family: 'Times New Roman', serif !important; }



.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: 'Comic Sans MS';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
  content: 'Courier New';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="dancing-script"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="dancing-script"]::before {
  content: 'Dancing Script';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="fira-code"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="fira-code"]::before {
  content: 'Fira Code';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="ibm-plex-mono"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="ibm-plex-mono"]::before {
  content: 'IBM Plex Mono';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="inter"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="inter"]::before {
  content: 'Inter';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="jetbrains-mono"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="jetbrains-mono"]::before {
  content: 'JetBrains Mono';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lato"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lato"]::before {
  content: 'Lato';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="merriweather"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="merriweather"]::before {
  content: 'Merriweather';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="montserrat"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="montserrat"]::before {
  content: 'Montserrat';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="nunito"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="nunito"]::before {
  content: 'Nunito';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="open-sans"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="open-sans"]::before {
  content: 'Open Sans';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="pacifico"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="pacifico"]::before {
  content: 'Pacifico';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="playfair-display"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="playfair-display"]::before {
  content: 'Playfair Display';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="poppins"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="poppins"]::before {
  content: 'Poppins';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before {
  content: 'Raleway';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  content: 'Roboto';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before, 
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  content: 'Times New Roman';
}
